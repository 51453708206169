<template>
    <div v-if="classeUser" class="d-flex mt-5">
        <b-button
            v-if="classeUser.sessionInfo"
            id="chatToggle"
            v-b-tooltip.hover.top="showChat ? 'Masquer le chat' : 'Ouvrir le chat'"
            pill
            variant="outline-primary"
            @click="showChat = !showChat"
        >
            <b-icon icon="chat" style="width: 100%; height: auto"></b-icon>
        </b-button>
        <!--begin::Dashboard-->
        <div :class="showChat ? 'mr-5' : ''" class="w-100">
            <div class="card card-custom gutter-b flex-row align-items-center justify-content-between p-5">
                <div class="w-25">
                    <router-link :to="{ name: 'dashboard' }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            {{ !mobile() ? $t('CLASS.BACK') : '' }}
                        </span>
                    </router-link>
                </div>
                <h1 class="ml-5 mb-0 align-self-center">{{ classeUser.classeInfo.title }}</h1>
                <div class="w-25" />
            </div>
            <div :class="mobile() ? 'flex-column' : ''" class="d-flex justify-content-between align-items-center">
                <div
                    :style="
                        mobile() ? '' : !isScorm || classeUser.state === 3 ? 'width: 65%; height: 20rem;' : 'width: 100%; height: 20rem;'
                    "
                    class="card card-custom gutter-b d-flex flex-row justify-content-around align-items-center py-2"
                >
                    <img
                        ref="EditMiniature"
                        :class="mobile() ? 'mb-10' : ''"
                        :src="classeUser.classeInfo.miniature"
                        alt="text"
                        class="m-0 rounded"
                        style="max-width: 60%; max-height: 100%; vertical-align: middle"
                    />
                    <section v-if="mobile() && (classeUser.state === 1 || (classeUser.state === 2 && !classeUser.score))">
                        <p class="mb-0">
                            <span class="font-weight-bold"> Score minimum à atteindre pour valider la classe </span>
                        </p>
                        <h3 class="p-5 text-center my-2">
                            {{ classeUser.classeInfo.goal + '%' }}
                        </h3>
                    </section>
                    <div
                        :style="mobile() ? 'max-width:100%;' : 'max-width:55%;'"
                        class="d-flex flex-column justify-content-center px-5"
                        style="min-width: 40%; max-height: 95%"
                    >
                        <span class="font-weight-bold pb-2">{{ $t('CLASS.DESCRIPTION.LABEL') }}</span>
                        <nl2br
                            v-if="classeUser.classeInfo.description"
                            :text="classeUser.classeInfo.description"
                            class-name="customScroll mh-100 my-2 ml-5 pl-5 border-secondary border-left nl2br"
                            tag="p"
                        />
                    </div>
                </div>
                <div
                    v-if="!mobile() && (!isScorm || classeUser.state === 3)"
                    :class="
                        classeUser.state === 1 || (classeUser.state === 2 && !classeUser.score)
                            ? 'justify-content-center'
                            : 'justify-content-between'
                    "
                    :style="mobile() ? '' : 'width: 32%;'"
                    class="card card-custom gutter-b p-10 align-items-center"
                    style="height: 20rem"
                >
                    <section v-if="classeUser.state === 1 || (classeUser.state === 2 && !classeUser.score)">
                        <p class="mb-0">
                            <span class="font-weight-bold"> Score minimum à atteindre pour valider la classe </span>
                        </p>
                        <h3 class="p-5 text-center my-2">
                            {{ classeUser.classeInfo.goal + '%' }}
                        </h3>
                    </section>
                    <p v-if="classeUser.state === 2 && classeUser.score" class="mb-0">
                        <span class="font-weight-bold"> Score à atteindre pour valider la classe : </span>
                        {{ classeUser.classeInfo.goal + '%' }}
                    </p>
                    <p v-if="classeUser.state === 2 && classeUser.score">
                        <span class="font-weight-bold"> Votre score: </span>
                        {{ classeUser.score + '%' }}
                    </p>
                    <p v-if="classeUser.state === 3" class="mb-0 font-weight-bold">Félicitations,</p>
                    <span v-if="classeUser.state === 3" class="text-center">
                        Vous avez terminé cette classe avec un score de {{ Number(classeUser.score).toFixed(0) + '%' }}.
                    </span>
                    <div
                        v-if="classeUser.state === 3"
                        v-b-tooltip.hover.top="
                            `${
                                classeUser.state !== 3
                                    ? 'Validez ' + classeUser.classeInfo.goal + '% des cours pour débloquer votre attestation'
                                    : ''
                            }`
                        "
                        class="w-100 mx-auto d-flex flex-column align-items-center justify-content-center"
                    >
                        <b-button
                            v-if="user.customer.id !== 2723 && user.customer.holding !== 2723 && user.customer.subholding !== 2723"
                            :class="classeUser.state !== 3 ? 'disabled' : ''"
                            :disabled="classeUser.state !== 3"
                            class="mt-2"
                            pill
                            size="sm"
                            variant="outline-primary"
                            @click="getDocument()"
                        >
                            <span class="svg-icon svg-icon-md">
                                <inline-svg src="/media/svg/eye.svg" width="20px" />
                            </span>
                            Voir mon attestation
                        </b-button>
                        <b-button
                            :class="classeUser.state !== 3 ? 'disabled' : ''"
                            :disabled="classeUser.state !== 3"
                            class="mt-2"
                            pill
                            size="sm"
                            variant="outline-primary"
                            @click="downloadDocument()"
                        >
                            <span class="svg-icon svg-icon-md">
                                <inline-svg src="/media/svg/diplome.svg" width="20px" />
                            </span>
                            Télécharger mon attestation
                        </b-button>
                        <b-button
                            v-if="classeUser.state === 3 && classeUser.classeInfo.summary"
                            :disabled="classeUser.state !== 3"
                            class="mt-2"
                            pill
                            size="sm"
                            variant="outline-primary"
                            @click.prevent="downloadSummary(classeUser.classeInfo.summary)"
                        >
                            <span class="svg-icon svg-icon-md">
                                <inline-svg src="/media/svg/pdf.svg" width="20px" />
                            </span>
                            Voir la fiche synthèse
                        </b-button>
                    </div>
                </div>
            </div>
            <div v-if="classeUser.classeInfo.classroom === 1" class="card card-custom gutter-b p-5 align-items-center w-100">
                <div class="w-100 h-100 mx-auto d-flex flex-column align-items-center">
                    <Visios :videoconferences="classeUser.videoconferences" />
                </div>
            </div>
            <div
                v-if="!classeUser.classeInfo.on && classeUser.state !== 3"
                class="card card-custom py-5 px-10 w-75 mx-auto gutter-b border border-danger font-weight-bold text-danger text-center"
            >
                <h4 class="m-0">Classe temporairement indisponible. Son contenu est en cours de révision</h4>
            </div>
            <div class="card card-custom gutter-b pt-5">
                <h3 class="mb-5 mx-5 mt-1">
                    Cours disponibles
                    <span class="text-muted font-weight-bold">( {{ isScorm ? 1 : classeUser.totalLessons }} )</span>
                </h3>
                <BAlert
                    v-if="classeUser.classeInfo.classroom === 1"
                    :show="
                        classeUser.classeInfo.classroom === 1 &&
                        (new Date(classeUser.sessionInfo.start_date) > new Date() || classeUser.sessionInfo.started === 0)
                    "
                >
                    <h4 class="alert-heading">
                        <b-icon icon="info-circle-fill"></b-icon>
                        Information
                    </h4>
                    <p v-if="new Date(classeUser.sessionInfo.start_date) > new Date()" class="m-0">
                        La session n'as pas encore commencée. Merci de vous reconnecter à partir du
                        {{ formatDate(classeUser.sessionInfo.start_date) }}.
                    </p>
                    <p v-else class="m-0">Vous pourrez commencer votre parcours de formation dès que votre tuteur l'aura activé.</p>
                </BAlert>
                <b-table
                    :fields="!mobile() ? (isScorm ? fieldsLessonsScorm : fieldsLessons) : fieldsLessonsMobile"
                    :items="isScorm ? scormTableRow : classeUser.lessons"
                    hover
                    tbody-tr-class="px-5"
                >
                    <template #cell(index)="row">
                        <div class="d-flex">
                            <p class="mb-0 ml-5">#{{ row.index + 1 }}</p>
                        </div>
                    </template>
                    <template #cell(title)="row">
                        <p class="mb-0">{{ row.item.title }}</p>
                    </template>
                    <template #cell(description)="row">
                        <nl2br
                            v-if="row.item.description"
                            :text="row.item.description"
                            class-name="customScroll my-2 nl2brLesson"
                            tag="p"
                        />
                    </template>
                    <template #cell(start)="row">
                        <p v-if="row.item.userResult" class="mb-0">{{ formatDate(row.item.userResult.created_at) }}</p>
                        <p v-else-if="row.item.start && isScorm" class="mb-0">{{ formatDate(row.item.start) }}</p>
                        <p v-else class="mb-0"></p>
                    </template>
                    <template #cell(finish)="row">
                        <p v-if="row.item.userResult && row.item.userResult.score !== null" class="mb-0">
                            {{ formatDate(row.item.userResult.updated_at) }}
                        </p>
                        <p v-else class="mb-0"></p>
                    </template>
                    <template #cell(duration)="row">
                        <!--row.item.duration is a value in seconds, we need to convert it to hours, minutes and seconds-->
                        <p class="mb-0">
                            {{
                                Math.floor(row.item.duration / 3600) +
                                'h ' +
                                Math.floor((row.item.duration % 3600) / 60) +
                                'm ' +
                                Math.floor((row.item.duration % 3600) % 60) +
                                's'
                            }}
                        </p>
                    </template>
                    <template #cell(avancement)="row">
                        <div class="d-flex align-items-center">
                            <div style="width: 100%">
                                <b-progress :max="row.item.totalItems" height="2vh" width="100%">
                                    <b-progress-bar v-if="row.item.userResult !== null && row.item.userResult.score !== null" :value="100">
                                        <span
                                            v-if="
                                                (row.item.totalItemsEnd / row.item.totalItems) * 100 === 100 ||
                                                (row.item.userResult && row.item.userResult.state === 3)
                                            "
                                        >
                                            Terminé
                                        </span>
                                        <span v-else-if="(row.item.totalItemsEnd / row.item.totalItems) * 100 > 10">
                                            {{ parseInt((row.item.totalItemsEnd / row.item.totalItems) * 100) }}%
                                        </span>
                                    </b-progress-bar>
                                    <b-progress-bar v-else-if="row.item.userResult !== null" :value="row.item.totalItemsEnd">
                                        <span
                                            v-if="
                                                (row.item.totalItemsEnd / row.item.totalItems) * 100 === 100 ||
                                                (row.item.userResult && row.item.userResult.state === 3)
                                            "
                                        >
                                            Terminé
                                        </span>
                                        <span v-else-if="(row.item.totalItemsEnd / row.item.totalItems) * 100 > 10">
                                            {{ parseInt((row.item.totalItemsEnd / row.item.totalItems) * 100) }}%
                                        </span>
                                    </b-progress-bar>
                                    <b-progress-bar v-else :value="row.item.totalItemsEnd">
                                        <span
                                            v-if="
                                                (row.item.totalItemsEnd / row.item.totalItems) * 100 === 100 ||
                                                (row.item.userResult && row.item.userResult.state === 3)
                                            "
                                        >
                                            Terminé
                                        </span>
                                        <span v-else-if="(row.item.totalItemsEnd / row.item.totalItems) * 100 > 10">
                                            {{ parseInt((row.item.totalItemsEnd / row.item.totalItems) * 100) }}%
                                        </span>
                                    </b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                    </template>
                    <template #cell(action)="row">
                        <div
                            v-if="
                                classeUser.sessionInfo &&
                                ((classeUser.sessionInfo.end_date && passedDate(classeUser.sessionInfo.end_date)) ||
                                    classeUser.sessionInfo.started === 0 ||
                                    new Date(classeUser.sessionInfo.start_date) > new Date())
                            "
                            v-b-tooltip.hover.top="
                                classeUser.sessionInfo.started === 0 || new Date(classeUser.sessionInfo.start_date) > new Date()
                                    ? 'La session n\'a pas encore démarrée'
                                    : 'Session terminée'
                            "
                            class="w-100 d-flex justify-content-center py-3"
                        >
                            <span class="disabled svg-icon svg-icon-danger svg-icon-md">
                                <inline-svg src="/media/svg/icons/General/Lock.svg" />
                            </span>
                        </div>
                        <div
                            v-else-if="
                                (row.index === 0 && classeUser.classeInfo.on) ||
                                (classeUser.classeInfo.linear &&
                                    row.index > 0 &&
                                    classeUser.lessons[row.index - 1].userResult &&
                                    classeUser.lessons[row.index - 1].userResult.state === 3 &&
                                    classeUser.classeInfo.on) ||
                                (!classeUser.classeInfo.linear && classeUser.classeInfo.on)
                            "
                            class="w-100 d-flex justify-content-center py-3"
                        >
                            <span v-if="row.item.userResult">
                                <div
                                    v-if="row.item.userResult && row.item.userResult.state === 3"
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <span class="svg-icon svg-icon-md svg-icon-warning">
                                        <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                                    </span>
                                    <p class="font-weight-bold text-warning ml-5 mb-0 pt-1">
                                        Validé
                                        <span v-if="row.item.userResult.score || classeUser.score"
                                            >(Score : {{ isScorm ? classeUser.score : row.item.userResult.score }}%)</span
                                        >
                                    </p>
                                </div>
                                <router-link
                                    v-else-if="row.item.userResult && row.item.userResult.state === 2"
                                    :to="{ name: 'lesson', params: { classeUserId: $route.params.classeUserId, id: row.item.id } }"
                                    replace
                                >
                                    <b-button
                                        class="btn-outline-primary d-flex justify-content-center align-items-center"
                                        variant="primary"
                                    >
                                        <p class="mb-0 ml-3 mr-1">Reprendre</p>
                                        <span class="svg-icon svg-icon-md svg-icon-light-success ml-0">
                                            <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                        </span>
                                    </b-button>
                                </router-link>
                                <router-link
                                    v-else
                                    :to="{ name: 'lesson', params: { classeUserId: $route.params.classeUserId, id: row.item.id } }"
                                    replace
                                >
                                    <b-button class="btn-outline-success d-flex" variant="success">
                                        <p class="mb-0 ml-3 mr-1">Commencer</p>
                                        <span class="svg-icon svg-icon-md svg-icon-light-success ml-0">
                                            <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                        </span>
                                    </b-button>
                                </router-link>
                            </span>
                            <b-button
                                v-else-if="isScorm && classeUser.state !== 3"
                                @click="generateScormUrl()"
                                class="btn-outline-success d-flex justify-content-center align-items-center px-2"
                                variant="success"
                                style="width: 10rem; height: 3rem"
                            >
                                <p v-if="!loadingDemo" class="mb-0 ml-3 mr-1">{{ classeUser.state === 1 ? 'Commencer' : 'Reprendre' }}</p>
                                <span v-if="!loadingDemo" class="svg-icon svg-icon-md svg-icon-light-success">
                                    <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                </span>
                                <b-spinner v-else small></b-spinner>
                            </b-button>
                            <div v-else-if="isScorm && classeUser.state === 3" class="d-flex justify-content-center align-items-center">
                                <span class="svg-icon svg-icon-md svg-icon-warning">
                                    <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                                </span>
                                <p class="font-weight-bold text-warning ml-5 mb-0 pt-1">
                                    Validé <span v-if="classeUser.score">(Score : {{ classeUser.score }}%)</span>
                                </p>
                            </div>
                            <router-link
                                v-else
                                :to="{ name: 'lesson', params: { classeUserId: $route.params.classeUserId, id: row.item.id } }"
                                replace
                            >
                                <b-button
                                    class="btn-outline-success d-flex justify-content-center align-items-center px-2"
                                    variant="success"
                                >
                                    <p class="mb-0 ml-3 mr-1">Commencer</p>
                                    <span class="svg-icon svg-icon-md svg-icon-light-success">
                                        <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                    </span>
                                </b-button>
                            </router-link>
                        </div>
                        <div
                            v-else
                            v-b-tooltip.hover.top="
                                classeUser.classeInfo.on
                                    ? 'Vous devez valider le cours précédent pour pouvoir continuer'
                                    : 'Classe indisponible'
                            "
                            class="w-100 d-flex justify-content-center py-3"
                        >
                            <span class="disabled svg-icon svg-icon-danger svg-icon-md">
                                <inline-svg src="/media/svg/icons/General/Lock.svg" />
                            </span>
                        </div>
                    </template>
                    <template #cell(summary)="row">
                        <a
                            v-if="row.item.summary && row.item.userResult && row.item.userResult.state === 3"
                            :href="row.item.summary"
                            target="_blank"
                            v-b-tooltip:top.hover="'Télécharger la fiche synthèse'"
                        >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                                <img src="/media/png/icons/export/PDF_icon.png" height="20px" alt="Icon PDF" />
                            </span>
                        </a>
                        <img
                            v-else-if="row.item.summary"
                            src="/media/png/icons/Course/pdf_gris.png"
                            height="20px"
                            alt="Icon PDF désactivé"
                            v-b-tooltip:top.hover="'Terminez la leçon pour télécharger la fiche synthèse'"
                        />
                        <p v-else class="m-0">Aucune</p>
                        <!--                        <b-button-->
                        <!--                            v-if="row.item.summary"-->
                        <!--                            :class="row.item.userResult.state !== 3 ? 'disabled' : ''"-->
                        <!--                            :disabled="row.item.userResult.state !== 3"-->
                        <!--                            class=" mt-2"-->
                        <!--                            pill-->
                        <!--                            size="sm"-->
                        <!--                            variant="outline-primary"-->
                        <!--                            @click="downloadSummary(row.item.summary)">-->
                        <!--                                <img src="/media/png/icons/export/PDF_icon.png" width="20px"/>-->
                        <!--                        </b-button>-->
                    </template>
                    <template #empty>
                        <p class="bg-secondary rounded py-5" disabled>
                            <i class="text-dark">{{ $t('LESSON.EMPTY') }}</i>
                        </p>
                    </template>
                </b-table>
            </div>
        </div>
        <div
            v-if="showChat"
            class="card card-custom gutter-b d-flex flex-row flex-wrap justify-content-around align-items-start py-2"
            style="width: 40%"
        >
            <h1>LE CHAT</h1>
        </div>
        <!--<img class="w-25" :src="dd.images.si2p" alt="" />-->
        <!--end::Dashboard-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { GET_CLASSEUSER } from '@/core/services/store/api/classe_users.service';
import { GENERATE_URL } from '@/core/services/store/api/scorm.service';
// BEGIN PDF MAKE IMPORT
import getDocument from '@/core/services/certificate.service.js';
// END PDF MAKE IMPORT
import Nl2br from 'vue-nl2br';
//import i18n from '@/core/plugins/vue-i18n.js';
import moment from 'moment';

// import Axios from 'axios';

export default {
    name: 'lesson',
    props: {
        isPresent: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loadingDemo: false,
            showChat: false,
            lessonId: null,
            classeUser: null,
            isScorm: false,
            fieldsLessonsMobile: [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'avancement',
                    label: 'Progression',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'action',
                    label: 'Action',
                    tdClass: 'align-middle'
                }
            ],
            fieldsLessonsScorm: [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'start',
                    label: 'Commencé le',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'action',
                    label: 'Action',
                    tdClass: 'align-middle'
                }
            ],
            user: this.$store.getters.currentUser,
            scormTableRow: []
        };
    },
    components: {
        Nl2br,
        Visios: () => import('@/view/content/components/Visios.vue')
    },
    computed: {
        checkUser() {
            if (this.classeUser.user_id === this.$store.getters.currentUser.id) {
                return true;
            } else {
                return false;
            }
        },
        fieldsLessons() {
            let baseFields = [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'start',
                    label: 'Commencé le',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'avancement',
                    label: 'Progression',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'action',
                    label: '',
                    tdClass: 'align-middle'
                }
            ];

            // Check if there are any completed lessons or available summaries
            if (
                this.classeUser.lessons.some((lesson) => lesson.userResult && lesson.userResult.state === 3) &&
                this.classeUser.lessons.some((lesson) => lesson.summary)
            ) {
                baseFields.push({
                    key: 'summary',
                    label: 'Fiche synthèse',
                    tdClass: 'align-middle text-center'
                });
            }

            return baseFields;
        }
    },
    async mounted() {
        if (!this.isPresent) {
            //display persistant toast
            this.$bvToast.toast("En raison d'une inactivité prolongée, vous avez été déconnecté de la classe.", {
                title: 'Vous avez été déconnecté de la classe',
                variant: 'danger',
                solid: true,
                noAutoHide: true,
                appendToast: true
            });
        }
        await this.getClasseUser();
        await this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Lesson' }]);
    },
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    methods: {
        getClasseUser() {
            this.$store
                .dispatch(GET_CLASSEUSER, { classeUser_id: this.$route.params.classeUserId })
                .then((result) => {
                    if (result.data.user_id !== this.$store.getters.currentUser.id) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.isScorm = result.data.classeInfo.scorm_id ? true : false;
                        if (this.isScorm) {
                            document.addEventListener('visibilitychange', this.handleVisibilityChange);
                            this.scormTableRow = [
                                {
                                    key: 1,
                                    title: result.data.classeInfo.title,
                                    description: result.data.classeInfo.description,
                                    start: result.data.updated_at ? result.data.updated_at : ''
                                }
                            ];
                        }
                        this.classeUser = result.data;
                        if (this.classeUser.classeInfo && this.classeUser.classeInfo.classroom === 1) {
                            //push to fieldLessons in 5th position
                            this.fieldsLessons.splice(5, 0, {
                                key: 'duration',
                                label: 'Temps passé',
                                tdClass: 'align-middle text-center'
                            });
                        }
                    }
                })
                .catch(() => {
                    this.$router.push({ name: 'dashboard' });
                });
        },
        handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                this.getClasseUser();
            }
        },
        passedDate(date) {
            return moment(date).isBefore(moment());
        },
        generateScormUrl() {
            this.loadingDemo = true;
            this.$store
                .dispatch(GENERATE_URL, { registrationId: this.$route.params.classeUserId })
                .then((result) => {
                    this.loadingDemo = false;
                    window.open(result.data, '_blank');
                })
                .catch((error) => {
                    this.loadingDemo = false;
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Erreur',
                        variant: 'danger',
                        solid: true,
                        autoHideDelay: 5000,
                        appendToast: true
                    });
                });
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        getDocument() {
            getDocument([this.classeUser]).then((data) => {
                data.open();
            });
        },
        downloadDocument() {
            getDocument([this.classeUser]).then((data) => {
                data.download(data.docDefinition.info.title);
            });
        },
        downloadSummary(url) {
            window.open(url, 'blank');
        },
        mobile() {
            return window.screen.availWidth <= 992;
        },
        hasSummary() {
            for (let i = 0; i < this.classeUser.lessons.length; i++) {
                if (this.classeUser.lessons[i].summary !== null) {
                    return true;
                }
            }
            return false;
        }
    }
};
</script>
<style scoped>
.table {
    text-align: center;
}

.table td {
    vertical-align: middle !important;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.nl2br {
    overflow-y: auto;
    text-align: left;
    max-height: 10vh;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: auto;
    /* text-align: left; */
    padding-right: 2px;
}

.customScroll::-webkit-scrollbar-track {
    background-color: #f1f4f7;
}

.customScroll::-webkit-scrollbar {
    width: 0.25em;
    background-color: #f1f4f7;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #085a82;
}

#chatToggle {
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 999;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
